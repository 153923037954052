html {
  height: 100%;
  _background: #ff00ff;
}
body {
  margin: 0px;
  background: #e6f1f7;
  width: 100%;
  height: 100%;
  _font-family: Arial;
  _font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;

  font-family: Trebuchet MS, Tahoma;

  _overflow: hidden;

  text-align: center;
  word-wrap: break-word;
}
img {
  border-width: 0px;
}
table {
  border-collapse: collapse;
}
table tr td,
table tr th {
  padding: 0px;
}

a {
  color: #f00;
}

#footerContent {
  _width: 980px;
  width: 920px;
  _width: 680px;
  padding: 10px;
  _padding-right: 140px;

  margin-left: auto;
  margin-right: auto;

  color: #919191;
  font-size: 80%;
  text-align: right;

  _background: #ffff00;
}
a.footerLinks {
  color: #919191;
  text-decoration: none;
}
#topLinks {
  width_: 100%;
  text-align: right;
}
#topLinksLeft_ {
  width: 200px;
}

a.topLinksA {
  display: block;
  background: #fff;
  color: #000000;
  text-decoration: underline;
  font-size: 100%;
  line-height: 28px;
  _margin: 0px 5px 0px 5px;
  padding: 0px 10px 0px 10px;
}
a.topLinksA:hover {
  color: #ffffff;
  background: #000000;
  text-decoration: none;
}

a.projectLink {
  color: #ffffff;
}
a.projectLink:hover {
  color: #ffffff;
  text-decoration: underline;
}

h1 {
  _color: #ff0000;
  font-size: 200%;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  _padding-top: 0px;
  _margin-top: 0px;
}
h2 {
  _color: #ff0000;
  font-size: 150%;
  font-weight: bold;
  text-transform: uppercase;
  _padding-top: 0px;
  _margin-top: 0px;
}
.contactText {
  color: #888888;
  padding-left: 10px;
}
.contactInput {
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  border-color: #666666 #bbbbbb #bbbbbb #666666;
  border-style: solid;
  border-width: 2px 1px 1px 2px;
}

.quote {
  font-style: italic;
}

.mainContent {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  width: 980px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 20px 20px 20px;
}

.menuContent {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  width: 980px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;

  padding: 0px;
  vertical-align: text-bottom;
}

.content1 {
  width: 100%;
  background: #e6f1f7;
}

.content2 {
  width: 100%;
  background: #f2f9ff;
}

.content3 {
  color: #fff;
}

.centered {
  text-align: center;
}
